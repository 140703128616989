import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Hero from '../../components/Hero'
import RichText from '../../components/RichText'
import ContentWrapper from '../../components/ContentWrapper'
import SectionHeading from '../../components/SectionHeading'
import PatternGrid from '../../components/PatternGrid'
import PatternCard from '../../components/PatternCard'
import LineLimitContainer from '../../components/LineLimitContainer'

const principleTemplate = ({ data: { principle } }) => (
  <Layout title={principle.name} seoDescription={principle.seoDescription}>
    <Hero
      type="principle"
      heading={principle.name}
      description={principle.description}
      heroImage={principle.heroIcon && principle.heroIcon}
    />
    <ContentWrapper>
      {principle.furtherDescription && (
        <section>
          <SectionHeading>Further description</SectionHeading>
          <LineLimitContainer>
            {RichText(principle.furtherDescription)}
          </LineLimitContainer>
        </section>
      )}
      {principle.pattern && (
        <section>
          <SectionHeading>Patterns</SectionHeading>
          <PatternGrid>
            {principle.pattern.map((pattern) => (
              <PatternCard
                key={pattern.slug}
                slug={pattern.slug}
                name={pattern.name}
                summary={pattern.summary}
              />
            ))}
          </PatternGrid>
        </section>
      )}
      {principle.furtherReading && (
        <section>
          <SectionHeading>Further reading</SectionHeading>
          <LineLimitContainer>
            {RichText(principle.furtherReading)}
          </LineLimitContainer>
        </section>
      )}
    </ContentWrapper>
  </Layout>
)

export const query = graphql`
  query($slug: String) {
    principle: contentfulPrinciple(slug: { eq: $slug }) {
      name
      seoDescription
      description {
        json
      }
      furtherDescription {
        json
      }
      heroIcon {
        file {
          url
        }
        description
      }
      pattern {
        id
        name
        slug
        summary
      }
      furtherReading {
        json
      }
    }
  }
`

export default principleTemplate
